// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position:       relative;
  margin-top:     $height + $gutter;
  margin-bottom:  $height + $gutter;
  user-select:    none;

  // 1. Fixes jagged edges in Firefox, see issue #10.
  &, &::before, &::after {
    display:             block;
    width:               $width;
    height:              $height;
    background-color:    $color;
    outline:             1px solid transparent; // 1
    @if $border-radius != 0 {
      border-radius: $border-radius;
    }
    transition-property: background-color, transform;
    transition-duration: $transition-duration;
  }

  &::before, &::after {
    position: absolute;
    content:  "";
  }

  &::before {
    top: -($height + $gutter);
  }

  &::after {
    top: $height + $gutter;
  }
}

// Burger animations
@mixin burger-to-cross($color: auto) {
  & {
    background-color: transparent;
  }
  @if ($color != auto) {
    &::before, &::after {
      background-color: $color;
    }
  }
  &::before {
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &::after {
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}

/*
.burger {
  @include burger(26px, 3px, 5px, $purple, 2px);
  &--activew {
    @include burger-to-cross;
  }
}
*/

/*

Flex grid

 */
[class*=flex-] {
  display:   flex;
  flex-wrap: wrap;
}

.flex--justify-center {
  justify-content: center;
}

.flex--align-center {
  align-content: center;
}

.flex__item--align-center {
  align-self: center;
}

@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 0 to $num-gaps {
      .flex-#{$breakpoint}--gap-#{$i}--x {
        margin-right: $i * (-($gap-size / 2));
        margin-left:  $i * (-($gap-size / 2));
      }

      .flex-#{$breakpoint}--gap-#{$i}--x > [class*=flex__item] {
        padding: 0 $i * (($gap-size / 2))
      }
      $i: $i+1;
    }
  }
}

@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 0 to $num-gaps {
      .flex-#{$breakpoint}--gap-#{$i}--y {
        margin-top: $i * (-$gap-size);
      }

      .flex-#{$breakpoint}--gap-#{$i}--y > [class*=flex__item] {
        margin-top: $i * $gap-size;
      }
      $i: $i+1;
    }
  }
}


@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 0 to $num-columns {
      $i: $i+1;
      .flex__item-#{$breakpoint}--col-#{$i} {
        $p:   percentage(($i/$num-columns));
        flex: 0 0 calc(#{$p});
      }
    }
  }
}

/*

Grid

 */
[class*=grid__item] {
  float:      left;
  width:      100%;
  min-height: 1px;
}

[class*=grid__item] > :last-child {
  margin-bottom: 0 !important;
}

[class*=grid__section] > :last-child {
  margin-bottom: 0 !important;
}

[class*=grid__section] > :last-child > :last-child {
  margin-bottom: 0 !important;
}

[class*=grid-]:before, [class*=grid-]:after {
  content: ' ';
  display: table;
}

[class*=grid-]:after {
  clear: both;
}

@supports (display:grid) {
  [class*=grid-] {
    display:           grid;
    grid-auto-flow:    row;
    grid-auto-columns: 1fr;
  }

  [class*=grid-]:before, [class*=grid-]:after {
    display: none;
  }

  [class*=grid-] > [class*=grid__item] {
    width: 100%;
  }
}

@supports (display:grid) {
  [class*=grid-] {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:    1fr;
  }

  @supports (grid-template-columns:minmax(0,1.1rem)) {
    [class*=grid-] {
      grid-template-columns: repeat(12, minmax(0, 1fr))
    }
  }
}


@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {

    [class*=grid__item-#{$breakpoint}] {
      display: block;
      clear:   none
    }
    @for $i from 0 to $num-columns {
      $i: $i+1;
      .grid__item-#{$breakpoint}--col-#{$i} {
        width: percentage($i/$num-columns)
      }

      @supports (display:grid) {
        .grid__item-#{$breakpoint}--col-#{$i} {
          grid-column-end: span $i
        }
      }
    }
  }
}

@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 0 to $num-gaps {
      .grid-#{$breakpoint}--gap-#{$i}--x {
        margin-right: $i * (-($gap-size / 2));
        margin-left:  $i * (-($gap-size / 2));
      }

      @supports (display:grid) {
        .grid-#{$breakpoint}--gap-#{$i}--x {
          margin:          0;
          grid-column-gap: $i * $gap-size;
        }
      }

      .grid-#{$breakpoint}--gap-#{$i}--x > [class*=grid__item] {
        padding: 0 $i * (($gap-size / 2))
      }

      @supports (display:grid) {
        .grid-#{$breakpoint}--gap-#{$i}--x > [class*=grid__item] {
          padding: 0
        }
      }

      $i: $i+1;
    }
  }
}

@each $breakpoint, $max-width in $breakpoints {
  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 0 to $num-gaps {
      .grid-#{$breakpoint}--gap-#{$i}--y {
        margin-top: $i * (-$gap-size);
      }

      @supports (display:grid) {
        .grid-#{$breakpoint}--gap-#{$i}--y {
          margin-top:   0;
          grid-row-gap: $i * $gap-size;
        }
      }

      .grid-#{$breakpoint}--gap-#{$i}--y > [class*=grid__item] {
        margin-top: $i * $gap-size;
      }

      @supports (display:grid) {
        .grid-#{$breakpoint}--gap-#{$i}--y > [class*=grid__item] {
          margin-top: 0
        }
      }
      $i: $i+1;
    }
  }
}

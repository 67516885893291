// base
body {
  font-weight:            400;
  font-smoothing:         antialiased;
  -webkit-font-smoothing: antialiased;
  @include font-size($fontsize);
  line-height:            1.4;
}

// titles
@each $h, $s in $fontsize-title {
  #{$h}, .#{$h} {
    @include font-size($s);
    font-family: $font-title;
  }
}

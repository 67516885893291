// colors
$black:               #000000;
$white:               #fbfbfb;
$blue: #a3cbdb;

$primary-color:       $black;
$secondary-color:     $white;

// breakpoints
$breakpoints:         (
                          xs: 0,
                          sm: 576px,
                          md: 768px,
                          lg: 992px,
                          xl: 1200px,
                          xxl: 1600px
                      );
// grid
$num-columns:         12;
$num-gaps:            10;
$gap-size:            5px;


// font families en sizes
$rfs-base-value:      1.25rem !default;
$rfs-unit:            rem !default;

$rfs-breakpoint:      map-get($breakpoints, xl) !default;
$rfs-breakpoint-unit: px !default;

$font-regular:        Helvetica, "Helvetica Neue", sans-serif;
$font-title:          sans-serif;

$fontsize:            1.3rem;
$fontsize-btn:        1.5rem;

$fontsize-title:      (
                          h1: 3.5rem,
                          h2: 2.6rem,
                          h3: 1.8rem,
                          h4: 1.7rem,
                          h5: 1.6rem
                      );

// margins
$marginbox:           7vw;
$marginbox-mobile:    25px;
$gutSize:             0px;
$dropdown-margin:     15px;

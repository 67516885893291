.section {
  display:    block;
  position:   relative;
  text-align: center;
  padding:    $marginbox ($marginbox / 2) 0 $marginbox;

  &__inner {
    margin:    0 auto;
    width:     100%;
    max-width: map-get($breakpoints, lg);
  }
}

@media screen and (max-width: map-get($breakpoints, xs)) {
  .section {
    padding: 25px 15px;
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .section {
    &__inner {

    }
  }
}

.section--footer {
  position:   relative;
  text-align: left;
  font-size:  90%;

}

@media screen and (max-width: map-get($breakpoints, s)) {
  .section--footer {

  }
}

.form {

  // form stuff ?

  ::-webkit-input-placeholder {
    color: rgba($white, 0.5);
  }

  ::-moz-placeholder {
    color: rgba($white, 0.5);
  }

  :-ms-input-placeholder {
    color: rgba($white, 0.5);
  }

  :-moz-placeholder {
    color: rgba($white, 0.5);
  }

}

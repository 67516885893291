.skip-link {
  display:          block;
  position:         fixed;
  left:             -1000em;
  z-index:          99;
  padding:          3px 5px;
  color:            $secondary-color;
  background-color: $primary-color;

  &:focus,
  &:active {
    left:    3px;
    top:     3px;
    z-index: 10002;
  }
}
.btn {
  @include font-size($fontsize-btn);
  display:         inline-block;
  border:          1px solid $white;
  padding:         13px 40px;
  text-decoration: none;
  cursor:          pointer;
}

@media screen and (max-width: map-get($breakpoints, s)) {
  .btn {

  }
}

/* LARGE */
@media screen and (min-width: map-get($breakpoints, l)) {
  .btn {

  }
}

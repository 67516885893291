* {
  box-sizing: border-box;
}

html {

}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  opacity:    0;
  background: linear-gradient($white, $blue);
  min-height: 100vh;
  font-size:  18px;

  &.loaded {
    opacity: 1;
  }
}

#site {
  position: relative;
}

.max-width-md {
  //max-width: map-get($breakpoints, md);
  max-width: 600px;
  margin:    0 auto;
}
.nav {
  position: fixed;
  z-index:  9999;
  width:    100%;
  top:      0;

  &__ul {
    list-style-type: none;
    margin:          0;
    padding:         0;
    position:        relative;
  }

  &__li {
    float: left;

    &__a {
      display:          block;
      text-align:       center;
      padding:          14px;
      text-decoration:  none;
      background-color: $secondary-color;
      color:            $primary-color;

      &:hover {
        background-color: $primary-color;
        color:            $secondary-color;
      }
    }
  }

}